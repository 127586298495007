<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="serviceStationInfo" label-width="150px" style="width:95%;overflow:auto">
      <el-form-item label="站点名称:" prop="Address">
          <p>{{serviceStationInfo.name}}</p>
      </el-form-item>
      <el-form-item label="站点地址:" prop="address">
          <p>{{pcaInfo+serviceStationInfo.address}}</p>
      </el-form-item>
      <el-form-item label="站点类型:" prop="Address">
          <p>{{serviceStationInfo.siteType == 1 ? '安装站点' : ( serviceStationInfo.siteType == 2 ? '检测站点' : '--')}}</p>
      </el-form-item>
      <el-form-item label="Gps定位值:" prop="Gps">
          <p>{{serviceStationInfo.longitude}},{{ serviceStationInfo.latitude }}</p>
      </el-form-item>
      <el-form-item label="工作日期范围:" prop="dateRange">
          <p>{{ serviceStationInfo.workStartTime }} ~ {{ serviceStationInfo.workEndTime }}</p>
      </el-form-item>
      <el-form-item label="每周工作日:" prop="WorkDate">
          <el-tag type="success" v-for="(item,index) in serviceStationInfo.weekTimeList" :key="index">{{item}}</el-tag>
      </el-form-item>
      <el-form-item label="工作时段:" prop="WorkTime">
          <el-tag type="success" v-for="(item,index) in serviceStationInfo.workTimeList" :key="index">{{item}}</el-tag>
      </el-form-item>
      <el-form-item label="单时最大预约量:" prop="appointmentMaxNum">
          <p>{{serviceStationInfo.appointmentMaxNum}}</p>
      </el-form-item>
      <el-form-item label="创建时间:" prop="createTime">
          <p>{{serviceStationInfo.createTime}}</p>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { getStationDetail,getParentTree } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
export default {
  props:['row'],
  data() {
    return {
      serviceStationInfo:{}, // 服务站详情
      pcaInfo:"",
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    close(){
       this.$emit('close');
    },
    async init(){
      await getStationDetail({param:this.row.id}).then(res=>{
          if(res.success){
              this.serviceStationInfo=res.data;
              let eachStartTime = timeChange(this.serviceStationInfo.workStartTime);
              this.serviceStationInfo.workStartTime = `${eachStartTime.year}-${eachStartTime.month}-${eachStartTime.day}`;
              let eachEndTime = timeChange(this.serviceStationInfo.workEndTime);
              this.serviceStationInfo.workEndTime = `${eachEndTime.year}-${eachEndTime.month}-${eachEndTime.day}`;
              let eachCreateTime = timeChange(this.serviceStationInfo.createTime);
              this.serviceStationInfo.createTime = `${eachCreateTime.year}-${eachCreateTime.month}-${eachCreateTime.day} ${eachCreateTime.hour}:${eachCreateTime.minutes}:${eachCreateTime.seconds}`;
              this.getParentPca(this.serviceStationInfo.regionId);
          }
      })
    },
    // 省市区：通过子级id获取父级id
    async getParentPca(regionId){
      let param = new URLSearchParams();
      param.append("param", regionId);
      await getParentTree(param).then(res=>{
        if(res.success){
          let all = res.data;
          this.digui(all,"")
        }
      })
    },
    digui(obj,address){
      address = obj.name + address;
      if(obj.parent){
        this.digui(obj.parent,address)
      }else{
        this.pcaInfo = address;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-tag.el-tag--success{
  margin-right: 10px;
  width: 80px;
}
 .el-drawer{
   overflow:auto !important;
 }
</style>

<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="170px" style="width:95%;overflow:auto">
      <el-form-item label="站点名称" prop="name">
        <el-input placeholder="请输入站点名称" v-model="form.name" :disabled="userInfo.roleLevel < 5 ? false : true"></el-input>
      </el-form-item>
      <el-form-item label="所属省/市/区/街道/社区" prop="regionId">
        <el-cascader
          :disabled="userInfo.roleLevel < 5 ? false : true"
          clearable
          placeholder="请选择"
          v-model="form.regionId"
          :options="regionTree"
          :props="{ expandTrigger: 'click',value:'id',label:'name',children:'childrenList'}"
          @change="chooseCityStreet"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="站点详细地址" prop="address" >
        <el-input placeholder="请填写站点详细地址" v-model="form.address" :disabled="userInfo.roleLevel < 5 ? false : true"></el-input>
      </el-form-item>
      <el-form-item  label="站点类型" prop="siteType" >
        <el-radio-group v-model="form.siteType" :disabled="userInfo.roleLevel < 5 ? false : true">
          <el-radio style="height: 40px;line-height: 40px;" :label="1">安装站点</el-radio>
          <el-radio style="height: 40px;line-height: 40px;" :label="2">检测站点</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item  label="所属商户" prop="merchantId"  v-if="userInfo.roleLevel < 5">
        <el-select v-model="form.merchantId" clearable placeholder="所属商户">
          <el-option
            v-for="item in merchantOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备编号" prop="deviceId" v-if="form.siteType == 2 && userInfo.roleLevel < 5">
        <el-input placeholder="请输入设备编号" v-model="form.deviceId"></el-input>
      </el-form-item>
      <el-form-item label="工作日期范围" prop="WorkDate" >
        <el-date-picker
          v-model="workDates"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="chooseDate">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="每周工作日" prop="weekTime" >
        <el-select v-model="form.weekTime" placeholder="请选择" multiple @change="chooseWeekDay">
           <el-option
             v-for="item in WorkWeekDay"
             :key="item.code"
             :label="item.value"
             :value="item.code"
            >
           </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工作时段" prop="workTime" >
        <el-select v-model="form.workTime" placeholder="请选择" multiple >
           <el-option
             v-for="item in WorkTime"
             :key="item.code"
             :label="item.value"
             :value="item.code"
            >
           </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单时最大预约量" prop="appointmentMaxNum" >
        <el-input placeholder="请输入单时最大预约量" type="Number" v-model="form.appointmentMaxNum"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button siteType="primary" @click="submit('form')" :plain='true'>提交</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { addStation,updateStation,getRegionTree,getStationDetail,getSiteWeekType,getSiteWorkingType,getParentTree,getMerchantList } from '@/api/public.js';
import { MakeMaxNumRule,getPointByAddress,timeChange,removeChildren,getCascaderLabel } from '@/api/publicFun.js';

export default {
  props:['row'], // 父组件携带参数
  data() {
    return {
      form:{ // 表单数据
          NeighbourhoodId:'',
          Address:'',
          Gps:'',
          startWorkDate:'',
          endWorkDate:"",
          workTime:[],
          weekTime:[],
          appointmentMaxNum:'',
          siteType:'',
          regionId:[],
      },
      neighborList:[],//小区列表
      WorkWeekDay:[ // 工作时间选项
        {label:1,text:'周一'},
        {label:2,text:'周二'},
        {label:3,text:'周三'},
        {label:4,text:'周四'},
        {label:5,text:'周五'},
        {label:6,text:'周六'},
        {label:7,text:'周日'},
      ],
      WorkTime:[ // 工作时刻选项
        {label:6,text:'06:00-07:00'},
        {label:7,text:'07:00-08:00'},
        {label:8,text:'08:00-09:00'},
        {label:9,text:'09:00-10:00'},
        {label:10,text:'10:00-11:00'},
        {label:11,text:'11:00-12:00'},
        {label:12,text:'12:00-13:00'},
        {label:13,text:'13:00-14:00'},
        {label:14,text:'14:00-15:00'},
        {label:15,text:'15:00-16:00'},
        {label:16,text:'16:00-17:00'},
        {label:17,text:'17:00-18:00'},
        {label:18,text:'18:00-19:00'},
        {label:19,text:'19:00-20:00'},
        {label:20,text:'20:00-21:00'},
        {label:21,text:'21:00-22:00'},
        {label:22,text:'22:00-23:00'},
      ],
      pcaOptions:[], // 省市区选项
      searchAddress: "", // 搜索地址
      addressInfo: { // 地址信息
        longitude: "", // 经度
        latitude: "", // 纬度
        province: "", // 省
        city: "", // 市
        district: "", // 区
        address: "", // 详细地址
      },
      open: false, // 选择地址弹框是否显示
      rules: { // 表单验证
        name: [
          { required: true, message: '站点名称不得为空', trigger: 'change', }
        ],
        address: [
          { required: true, message: '站点详细地址不得为空', trigger: 'blur', }
        ],
        siteType: [
          { required: true, message: '请选择站点类型', trigger: 'change', },
        ],
        appointmentMaxNum: [
          { required: true, message: '单时最大预约量不得为空', trigger: 'blur', },
          { validator: MakeMaxNumRule, trigger: 'blur' }
        ],
        regionId:[
          { required: true, message: '请选择省市区、街道、社区等信息', trigger: 'blur', },
        ]
      },
      userGps:[], // 用户当前所在位置GPS
      workDates:null,
      regionTree:[], // 地区下拉框数据
      merchantOptions:[
        {name:'乐电',id:1}
      ], // 所属商户选项
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
    };
  },

  components: {},

  computed: {},

  mounted() {
    console.log(this.userInfo.roleLevel < 5 ,999)
    if(this.row){
      this.form = this.row;
      getStationDetail({param:this.row.id}).then(res=>{
        if(res.success){
          this.form = res.data;
          this.form.workTime = JSON.parse(this.form.workTime);
          this.form.weekTime = JSON.parse(this.form.weekTime);
          this.workDates = [this.form.workStartTime,this.form.workEndTime];
          this.chooseDate(this.workDates);
          this.getParentPca(this.form.regionId)
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
      })
    }
    this.getPCA();
    this.getDateTypeList();
    this.getMerchantOptions();
  },

  methods: {
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.merchantOptions = res.data.list;
        }else{
          this.merchantOptions = [];
        }
      })
    },
    // 获取工作时段、周工作日期枚举
    async getDateTypeList(){
      await getSiteWeekType().then(res=>{
        if(res.success){
          this.WorkWeekDay = res.data;
        }else{
          this.WorkWeekDay = [];
        }
      })
      await getSiteWorkingType().then(res=>{
        if(res.success){
          this.WorkTime = res.data;
        }else{
          this.WorkTime = [];
        }
      })
    },
    // 获取省市区联动数据
    async getPCA(){
      await getRegionTree().then(res=>{
        if(res.success){
          this.regionTree = res.data;
          this.regionTree.forEach(element => {
            element = removeChildren(element);
          });
        }else{
          this.regionTree = [];
        }
      })
    },
    // 省市区：通过子级id获取父级id
    async getParentPca(id){
      let param = new URLSearchParams();
      param.append("param", id);
      await getParentTree(param).then(res=>{
        if(res.success){
          let all = res.data;
          this.digui(all,[],"")
        }
      })
    },
    // 递归
    digui(obj,regionArr,address){
      regionArr.unshift(obj.id);
      address = obj.name + address;
      if(obj.parent){
        this.digui(obj.parent,regionArr,address);
      }else{
        this.form.regionId = regionArr;
        this.pcaInfo = address;
      }
    },
    // 选择省市区
    chooseCityStreet(e){
      if(e){
        let text = "";
        this.pcaInfo = getCascaderLabel([...e],this.cityOptions,text);
      }
    },
    // 关闭弹窗
    close(form){
       this.$emit('close');
    },
    // 获取用户当前位置
    getLocation(){
      var geolocation = new BMapGL.Geolocation();
      geolocation.getCurrentPosition((r)=>{
        this.userGps[0]=r.longitude;
        this.userGps[1]=r.latitude;
      },{enableHighAccuracy: false,maximumAge:1000})
    },
    // 选择工作日期范围
    chooseDate(e){
      let start = timeChange(e[0]);
      let end = timeChange(e[1]);
      this.form.workStartTime = `${start.year}-${start.month}-${start.day}`;
      this.form.workEndTime = `${end.year}-${end.month}-${end.day}`;
    },
    // 选择每周工作日
    chooseWeekDay(e){},
    // 新增
    async add(){
      await addStation({param:this.form}).then(res=>{
        if(res.success){
          this.$message({
            message:'提交成功',
            type:'success'
          })
          setTimeout(()=>{
            this.$emit('close');
          },1000)
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
      })
    },
    // 编辑
    async edit(){
      await updateStation({param:this.form}).then(res=>{
        if(res.success){
          this.$message({
            message:'提交成功',
            type:'success'
          })
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
        setTimeout(()=>{
          this.$emit('close');
        },1000)
      })
    },
    // 提交
    submit(form) {
      let address = this.pcaInfo+this.form.address;
      getPointByAddress(address).then(res=>{
        this.form.latitude = res.lat;
        this.form.longitude = res.lng;
      });
      this.$refs["form"].validate(valid => {
          if (valid) {
              this.$confirm('确认提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                siteType: 'warning'
              }).then(()=>{
                let inde = this.form.regionId.length - 1;
                this.form.regionId = this.form.regionId[inde];
                this.form.workTime=JSON.stringify(this.form.workTime.sort((a,b)=>{return a-b}));
                this.form.weekTime=JSON.stringify(this.form.weekTime.sort());
                if(this.row){ // 编辑
                  this.edit();
                }else{ // 新增
                  this.add();
                }
              })
          }else{
            return false;
          }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
// 防止地图自动完成的对象被遮挡
.tangram-suggestion {
    z-index: 9999 !important;
  }
 .el-drawer{
   overflow:auto !important;
 }
 /deep/ .el-form-item__content{
  display: flex;
 }
 .map{
  border: 1px solid #dcdfe6;
  background: #fff;
  border-radius: 4px;
  width: 80px;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>

<!-- 站点管理 -->
<template style="height:100%">
  <div class="mainContainer outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-input class="each" v-model="searchInfo.param.name" placeholder="站点名称">
      </el-input>
      <el-select class='each' v-model="searchInfo.param.siteType" clearable placeholder="站点类型">
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <!-- 当前登录账户角色level 小于5 的才有商户选项 -->
      <el-select class='each' v-model="searchInfo.param.merchantId" clearable placeholder="所属商户" v-if="userInfo.roleLevel < 5">
        <el-option
          v-for="item in merchantOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="hanldeSearch('search')">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close" @click="hanldeSearch('clear')">清空搜索条件</el-button>
      <el-button size="mini" @click="openDrawer(null,'add')"  v-if="userInfo.roleLevel < 5">新增</el-button>
    </div>
    <div class="tableContainer">
    <!-- 数据表格 -->
      <el-table
       :data="tableList"
       height="100%"
       style="width: 100%;">
        <el-table-column align="center" label="站点名称" prop="name">
        </el-table-column>
        <el-table-column align="center" label="站点类型" prop="siteType">
          <template slot-scope="scope">
            <p>{{ scope.row.siteType == 1 ? '安装站点' : '检测站点' }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="检测房设备" prop="detectionHouseId">
          <template slot-scope="scope">
            <div v-if="scope.row.siteType == 2">
              <p>
                {{ scope.row.testingRoomVO ? scope.row.testingRoomVO.name : '' }}
                <el-button size="mini" @click="releaseBindEquip(scope.row)" v-if="scope.row.testingRoomVO">解绑</el-button>
                <el-button type="info" size="mini" @click="openDrawer(scope.row,'bindEquip')" v-else>绑定</el-button>
              </p>
            </div>
            <p v-else>--</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="工作人员" prop="voList" v-if="userInfo && userInfo.roleLevel <= 5">
          <template slot-scope="scope">
            <p v-if="scope.row.voList && scope.row.voList.length == 1" >
              {{ scope.row.voList[0].account }}
              <el-button size="mini" @click="releaseBind(scope.row.voList[0],scope.row)">解绑</el-button>
            </p>
            <el-dropdown trigger="click" v-if="scope.row.voList && scope.row.voList.length > 1">
              <span class="el-dropdown-link">
                {{scope.row.voList[0].account}}
                <el-button size="mini" @click="releaseBind(scope.row.voList[0],scope.row)">解绑</el-button>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" style="max-height:200px;overflow-y: auto;">
                <el-dropdown-item v-for="(item,index) in scope.row.voList" :key="index">
                    {{item.account}}
                    <el-button size="mini" @click="releaseBind(item,scope.row)">解绑</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button type="info" size="mini" @click="openDrawer(scope.row,'bind')" v-if="userInfo && userInfo.roleLevel <= 5" style="margin-top: 5px;">绑定</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="单时最大预约量" prop="appointmentMaxNum">
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTimeStr">
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="250">
          <template slot-scope="scope">
            <p style="margin-bottom: 5px;"> 
              <el-button size="mini" @click="openDrawer(scope.row,'edit')">编辑</el-button>
              <el-button size="mini" @click="openDrawer(scope.row,'detail')">详情</el-button>
            </p>
            <p>
              <el-button size="mini" @click="detectionList(scope.row)">预约记录</el-button>
              <el-button size="mini" @click="handleDelete(scope.row)" v-if="userInfo.roleLevel < 5">删除</el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
      <!-- 新增、编辑 -->
      <el-drawer :title="row ? '编辑' : '新增'"
      :visible.sync="addDrawer"
      size="50%">
      <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row"></add>
      </el-drawer>
      <!-- 详情 -->
      <el-drawer title="详情"
      :visible.sync="detailDrawer"
      size="50%">
      <detail v-if="detailDrawer" @close="handleClose"  ref='newForm' :row="row"></detail>
      </el-drawer>
      <!-- 绑定 -->
      <el-drawer title="绑定工作人员"
        :visible.sync="bindDrawer"
        :before-close="handleClose"
      >
        <bind v-if="bindDrawer" @close='handleClose' ref='newForm' :row="row"></bind>
      </el-drawer>
      <!-- 绑定检测房设备 -->
      <el-dialog title="检测房设备绑定" :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body>
          <el-form label-width="180px" :model="detectionHouseInfo" ref="detectionHouseInfo">
            <el-form-item label="检测房设备" prop="detectionHouseId">
              <el-select placeholder="输入检测房名称进行查询" filterable remote v-model="detectionHouseInfo.detectionHouseId" clearable
               :remote-method="getDetectionHouse"
               @change="chooseDetection"
               @clear="clearDetection"
               style="width: 100%;">
                <el-option
                 v-for="(item,index) in detectionHouseOptions"
                 :key="index"
                 :label="item.name + ' - '+ item.id"
                 :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span> <!-- 检测房名称 -->
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.id }}</span> <!-- 检测房编号 -->
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">关闭</el-button>
            <el-button @click="bindEquip">提交</el-button>
          </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import add from './add.vue';
import detail from './detail.vue';
import bind from './bind.vue';
import pagination from '@/components/Pagination.vue';
import { getStationList,delStation,unbindSiteUser,getMerchantList,getParentTree,getDetectionHouseList,bindDetectionHouse,unBindDetectionHouse } from '@/api/public.js';
import {timeChange} from '@/api/publicFun.js';
export default {
  data() {
    return {
      currentPage:1, // 当前页码
      searchInfo:{ // 查询数据条件
        pageSize:10,
        pageNo:1,
        total:0,
        param:{
          name:null,
          siteType:null,
          merchantId:null,
        }
      },
      tableList:[], // 表格数据
      row:null, // 当前操作数据具体信息
      typeOptions:[ // 站点类型选项
        {value:1,label:"安装站点"},
        {value:2,label:"检测站点"},
      ],
      merchantOptions:[], // 商户选项
      siteType:'', // 站点类型
      // 弹框控制参数
      addDrawer:false,
      detailDrawer:false,
      bindDrawer:false,
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
      detectionHouseOptions:[], // 检测房设备列表
      detectionHouseInfo:{}, // 绑定检测房表单
      dialogVisible:false, // 绑定检测房弹框是否显示
      detectionHouseSearch:{
        pageNo:1,
        pageSize:10,
        param:{
          name:"",
        }
      }
    }
  },

  components: {add,detail,bind,pagination},

  computed: {},

  mounted() {
    this.init();
    this.getMerchantOptions();
  },

  methods: {
    // 搜索事件
    hanldeSearch(type){
      this.searchInfo.pageNo = 1;
      if(type == 'clear'){
        this.searchInfo.param = {};
      }else{
        let param = this.searchInfo.param
        for(let key in param){
          if( param[key] == ""){
            param[key] = null;
          }
        }
      }
      this.init();
    },
    // 获取检测房列表
    getDetectionHouse(e){
      this.detectionHouseSearch.param.name = e ? e : null;
      getDetectionHouseList(this.detectionHouseSearch).then(res=>{
        this.detectionHouseOptions = res.success ? res.data.list : [];
      })
    },
    // 选择微信用户
    chooseDetection(e){
      this.detectionHouseInfo.detectionHouseId = e;
      this.detectionHouseInfo = {...this.detectionHouseInfo};
    },
    // 清空选择
    clearDetection(){
      this.detectionHouseSearch.param.name = null;
      this.getDetectionHouse();
    },
    // 初始化获取列表数据
    async init(){
      await getStationList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableList = res.data.list;
          this.searchInfo.total = res.data.total;
          this.tableList.forEach( async (element,index) => {
            element.createTimeStr = element.createTime ? timeChange(element.createTime,'seconds') : '--';
          });
        }else{
          this.searchInfo.total = 0;
          this.tableList = [];
        }
      })
    },
    // 省市区：通过子级id获取父级id
    getParentPca(id){
      let param = new URLSearchParams();
      param.append("param", id);
      return new Promise((resolve) => {
        getParentTree(param).then( res =>{
          if(res.success){
            let all = res.data;
            let address = this.digui(all,[],"");
            resolve(address);
          }
        })
      })
    },
    // 递归
    digui(obj,regionArr,address){
      regionArr.unshift(obj.id);
      address = obj.name + address;
      if(obj.parent){
        return this.digui(obj.parent,regionArr,address);
      }else{
        return address;
      }
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.merchantOptions = res.data.list;
        }else{
          this.merchantOptions = [];
        }
      })
    },
    // 打开弹框
    openDrawer(row,type){
      this.row = row;
      if(type == 'add' || type == 'edit'){
        this.addDrawer = true;
      }else if(type == 'detail'){
        this.detailDrawer = true;
      }else if(type == 'bind'){
        this.bindDrawer = true;
      }else if(type == 'bindEquip'){
        this.detectionHouseInfo = {...row};
        this.dialogVisible = true;
        this.getDetectionHouse();
      }
    },
    // 关闭侧边弹窗
    handleClose(){
      this.row = null;
      this.addDrawer = false;
      this.detailDrawer = false;
      this.bindDrawer = false;
      this.init();
    },
    // 解绑管理员
    releaseBind(userRow,row){
      event.stopPropagation()
      this.$confirm('确认进行解绑吗？').then(async ()=>{
        let data = {
          userId:userRow.id,
          siteId:row.id,
        }
        await unbindSiteUser({param:data}).then(res=>{
          if(res.success){
            this.$message({
              message:'解绑成功',
              type:'success'
            });
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            });
          }
        })
      })
    },
    // 删除
    handleDelete(row){
      this.$confirm('确认删除吗？').then( async ()=>{
        await delStation({param:row.id}).then(res=>{
          if(res.success){
            this.$message({
              message:'删除成功',
              type:'success'
            })
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
            this.init();
          }
        })
      })
    },
    // 查看安装预约记录或检测预约记录
    detectionList(row){
      if(row.siteType == 1){
        this.$router.push({path:"/serviceStation/installRecords",query:{id:row.id}})
      }else{
        this.$router.push({path:"/serviceStation/detectionRecords",query:{id:row.id}})
      }
    },
    // 解绑检测房设备
    releaseBindEquip(row){
      this.$confirm('确认执行该操作吗？').then(async ()=>{
        let data = {
          param:{
            siteId:row.id,
            testingRoomId:row.testingRoomVO.id,
          }
        }
        await unBindDetectionHouse(data).then(res=>{
          this.$message({
            message:res.success ? '操作成功' : res.msg,
            type:res.success ? 'success' : 'fail'
          })
          this.init();
        })
      })
    },
    // 绑定检测房设备
    async bindEquip(row){
      let data = {
        param:{
          siteId:this.detectionHouseInfo.id,
          testingRoomId:this.detectionHouseInfo.detectionHouseId,
        }
      }
      await bindDetectionHouse(data).then(res=>{
        this.$message({
          message:res.success ? '操作成功' : res.msg,
          type:res.success ? 'success' : 'fail'
        })
        this.dialogVisible = res.success ? false : true;
      })
      this.init();
    },
    // 分页插件事件
    callFather(parm) {
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss"> 
</style>

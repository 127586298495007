<template>
    <div>
        <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
            <el-form-item label="站点" prop="name" >
              <p style="font-weight: 600;">{{ form.name }}</p>
            </el-form-item>
            <el-form-item label="后台用户" prop="controlId" >
              <el-select class='select' v-model="form.controlId" placeholder="请选择">
                <el-option
                  v-for="item in userOptions"
                  :key="item.id"
                  :label="item.account"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="close('form')">取消</el-button>
              <el-button type="primary" @click="bind('form')" :plain='true'>提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import{ bindSiteUser,getUserList,selectSiteUserByRole } from '@/api/public.js'
export default {
    props:["row"],
    data() {
        return {
            form:{
              controlId:[],
            },
            userOptions:[], // 
            rules: { // 表单验证
            },
        }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
        this.form={...this.row};
        this.searchUser();
    },
  
    methods: {
      close(){
        this.$emit('close');
      },
      // 筛选后台用户
      searchUser(e){
        selectSiteUserByRole({}).then(res=>{
          if(res.success){
            this.userOptions = res.data;
          }else{
            this.userOptions = [];
          }
        })
      },
      // 提交绑定
      bind(){
        this.$refs["form"].validate(valid => {
            if (valid) {
                this.$confirm('确认绑定吗？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(()=>{
                  let data = {
                    userId : this.form.controlId,
                    siteId : this.row.id
                  }
                    bindSiteUser({param:data}).then(res=>{
                        if(res.success){
                            this.$message({
                              message: "绑定成功",
                              type: "success",
                            });
                            setTimeout(() => {
                              this.$emit("close");
                              this.init();
                            }, 1000);
                        }else{
                            this.$message({
                              message: "绑定失败",
                              type: "fail",
                            });
                        }
                    })
                })
            }
        })
        
      }
    },
};
</script>
<style scoped lang="scss">
  /deep/ .el-select{
    width: 100%;
  }
</style>
